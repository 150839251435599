import { gql } from 'apollo-angular';

export const getUserLocations = gql`
  query getUserLocations($user_uuid: uuid) {
    locations(order_by: { updated_at: desc }, where: {user_uuid: {_eq: $user_uuid}}) {
      city
      state
      complement
      name
      neighborhood
      number
      street
      zipcode
      uuid
      updated_at
      user_uuid
    }
  }
`;

export const upsertUserLocation = gql`
  mutation upsertUserLocation(
    $uuid: uuid
    $city: String
    $state: String
    $user_uuid: uuid
  ) {
    insert_locations_one(
      object: {
        uuid: $uuid
        city: $city
        state: $state
        user_uuid: $user_uuid
      }
      on_conflict: {
        constraint: locations_pkey
        update_columns: [
          city
          state
        ]
      }
    ) {
      uuid
      city
      state
      user_uuid
    }
  }
`;

export const inactivateLocation = gql`
  mutation inactivateLocation($uuid: uuid!) {
    update_locations_by_pk (
      pk_columns: {uuid: $uuid}
      _set: {is_active: false}
    ) {
      uuid,
      is_active
    }
  }
`;