import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Apollo, gql } from 'apollo-angular';

const getAllCities = gql`
  query getAllCities {
    d_cities {
      city
      state
      uuid
    }
  }
`;

@Component({
  selector: 'app-autocomplete-city-list',
  templateUrl: './autocomplete-city-list.component.html',
  styleUrls: ['./autocomplete-city-list.component.scss'],
})
export class AutocompleteCityListComponent implements OnInit {
  citiesLazy = [];
  cities = [];
  allCities = [];
  constructor(private apollo: Apollo, private modal: ModalController) { }

  ngOnInit() {
    this.apollo.query({
      query: getAllCities,
      fetchPolicy: 'cache-first'
    }).subscribe(
      (res: any) => {
        if(res.data) {
          this.allCities = [...res.data.d_cities];
        }
      }
    )
  }

  emmitCityFn(cityData: string){
    this.modal.dismiss(cityData);
  }

  closeModal() {
    this.modal.dismiss();
  }

  stopPropagation(event: any) {
    event.stopPropagation();
  }

  filterCities(event: any){
    this.cities = [...this.allCities.filter(({city}) => this.normalize(city).includes(this.normalize(event.target.value)))];
    this.citiesLazy = [...this.cities.splice(0, 29)];
  }

  loadData(event) {

    this.citiesLazy.push(...this.cities.splice(0, 29));    
    event.target.complete();
  }

  normalize(word: string) {
    return `${word}`
      .toLowerCase()
      .trim()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  }

}
