import { first } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { getUserProfile, getUserProfileAllFields, insertUser } from '../graphql/user';
import { getUserAdvertisementsCards, getUserSavedAds } from '../graphql/advertisements';
import firebase from 'firebase';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private apollo: Apollo) {}

  insertUser = (
    is_company: boolean,
    email: string,
    whatsapp_phone: string,
    name: string,
    lastname: string = null
  ): Promise<any> =>
    this.apollo
      .mutate({
        mutation: insertUser,
        variables: {
          is_company,
          lastname,
          name,
          whatsapp_phone,
          email
        },
      })
      .pipe(first())
      .toPromise();

  queryUserProfile = async () => {
    const firebase_uid = firebase.auth().currentUser.uid;
    return this.apollo
      .query({
        query: getUserProfile,
        variables: {
          firebase_uid
        },
        fetchPolicy: 'no-cache'
      })
      .pipe(first()).toPromise();
  }

  getUserProfile = async () => {
    const res:any = await this.queryUserProfile();
      if(!!res.data.user.length){
        return res.data.user[0];
      }
  }

  getUserProfileAllFields = async () => {
    const firebase_uid = firebase.auth().currentUser.uid;
    return this.apollo
      .query({
        query: getUserProfileAllFields,
        variables: {
          firebase_uid
        },
        fetchPolicy: 'no-cache'
      })
      .pipe(first()).toPromise();
  }

  getUserAdvertisementsCards = async () => {
    const user:any = await this.getUserFromLocalStorage();
    return this.apollo.subscribe({
      query: getUserAdvertisementsCards,
      variables: {
        "user_uuid": user.uuid
      }
    });
  }

  getUserSavedAds = async () =>
    this.apollo.query({
      query: getUserSavedAds,
      fetchPolicy: 'no-cache'
    }).pipe(first()).toPromise();

  persistUserInLocalStorage = (user: any) =>
    localStorage.currentUser = JSON.stringify(user);

  getUserFromLocalStorage = async () => {
    let user;
    try {
      user = JSON.parse(localStorage.currentUser);
      if(!user) throw new Error();
    } catch(err) {      
      user = await this.getUserProfile();
      this.persistUserInLocalStorage(user);
    } finally {
      return user;
    }
  }
}
