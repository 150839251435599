import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';
import { HttpLink } from '@apollo/client/link/http';
import { WebSocketLink } from '@apollo/client/link/ws';
import { split } from '@apollo/client/core';
import { getMainDefinition } from '@apollo/client/utilities';
import firebase from 'firebase';
import { ToastController } from '@ionic/angular';



@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private apollo: Apollo,
    private userSv: UserService,
    private toast: ToastController,
  ) {}

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  async login(email: string, password: string): Promise<boolean> {
    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);
      const toast = await this.toast.create({
        message: 'Login efetuado com sucesso. Redirecionando...',
        duration: 3000,
        color: 'success',
      });
      toast.present();
    } catch (error) {
      console.log(error.message);
      const toast = {
        message: error.message === 'There is no user record corresponding to this identifier. The user may have been deleted.'
          ? 'Email não registrado. Crie um novo cadastro' : 'Credenciais inválidas.',
        duration: 3000,
        color: 'warning',
      };
      (await this.toast.create(toast)).present();
      await this.logout();
      // console.log('Authentication failed: ', error);
      return false;
    }
    return true;
  }

  async register(email: string, password: string): Promise<boolean> {
    try {
      localStorage.registerProcess = true;
      await firebase.auth().createUserWithEmailAndPassword(email, password);
    } catch (error) {
      localStorage.removeItem('registerProcess');
      const userExists = error.message === 'The email address is already in use by another account.';
      const toast: any = {
        color: 'danger',
        duration: 4000,
        message: userExists ? 'Email já cadastrado na plataforma, tente fazer login.'
          :'Algo de errado aconteceu. Tente novamente em alguns minutos.'
      };
      (await this.toast.create(toast)).present();
      return false;
    }
    return true;
  };

  sendResetPasswordMail(email: string): Promise<void> {
    return firebase.auth().sendPasswordResetEmail(email);
  }

  async handleAuthentication(){
    try {
      await Promise.all([this.setHasuraJwt()]);
      const userProfile = await this.userSv.getUserProfile();
      if(!userProfile) {
        return 'newUser';
      }
      this.userSv.persistUserInLocalStorage(userProfile);
    } catch(err) {
      // console.log(err);
      await this.logout();
    }
  }

  deleteUser = async (password: string = null, email = null) => {
    if(!email){
      const user = await this.userSv.getUserFromLocalStorage();
      email = user?.email;
    }
    if(email && password){
      const credentials = firebase.auth.EmailAuthProvider.credential(email, password);
      await firebase.auth().currentUser.reauthenticateWithCredential(credentials);
    }
    await firebase.auth().currentUser.delete();
    localStorage.clear();
  };

  logout = async () => {
    // console.log('logout');
    await firebase.auth().signOut();
    localStorage.clear();
  };

  getCurrentUser = () =>
    firebase.auth().currentUser;

  getUserJwtToken(
    forceRefresh: boolean = false
  ): Promise<string | undefined> {
    return firebase.auth().currentUser?.getIdToken(forceRefresh);
  }

  async getHeadersWithJwtToken() {
    const token = await this.getUserJwtToken(true)
    .catch(() => null);
    return token ? {Authorization: `Bearer ${token}`} : null;
  }

  async setHasuraJwt() {

    if (!this.getCurrentUser()) {
      // console.log('@setHasuraJwt :dont have a currentUser');
      return;
    }

    const http = new HttpLink({
      uri: environment.hasura_url,
      fetch: async (uri: RequestInfo, options: RequestInit) => {
        options.headers = await this.getHeadersWithJwtToken();
        return fetch(uri, options);
      },
    });

    const ws = new WebSocketLink({
      uri: environment.hasura_ws_url,
      options: {
        reconnect: true,
        connectionParams: async () => ({
            headers: await this.getHeadersWithJwtToken()
          })
      }
    });

    const link = split(
      ({ query }) => {
        const data = getMainDefinition(query);
        return (
          data.kind === 'OperationDefinition' &&
          data.operation === 'subscription'
        );
      },
      ws,
      http
    );
    return this.apollo.client.setLink(link);
  }
}
