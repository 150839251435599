import { AppPopoverComponent } from './shared/components/cards/location-card/location-card.component';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { environment } from 'src/environments/environment';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';
import { HttpClientModule } from '@angular/common/http';
import { NgxMaskModule } from 'ngx-mask';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';


import ptBr from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
registerLocaleData(ptBr);


@NgModule({
  declarations: [AppComponent, AppPopoverComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(
      {animated: true}
    ),
    AppRoutingModule,
    ApolloModule,
    HttpClientModule,
    NgxMaskModule.forRoot({validation: false}),
    ScrollingModule
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: 'pt' },
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink) => {
        return {
          cache: new InMemoryCache({}),
          link: httpLink.create({
            uri: environment.hasura_url,
          }),
          defaultOptions: {
            watchQuery: {
                fetchPolicy: 'cache-and-network',
                errorPolicy: 'ignore',
            },
            query: {
                fetchPolicy: 'no-cache',
                errorPolicy: 'all',
            },
            mutate: {
              errorPolicy: 'all'
            },
            subscription: {
              fetchPolicy: 'no-cache',
              errorPolicy: 'all',
            }
          }
        };
      },
      deps: [HttpLink],
    },
    ScreenOrientation
  ],
  bootstrap: [AppComponent],
  exports: [BrowserModule, HttpClientModule, ApolloModule, AppPopoverComponent],
})
export class AppModule {}
