import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';

const routes: Routes = [
  {
    path: 'main-screens',
    loadChildren: () => import('./pages/main-screens/main-screens.module').then( m => m.MainScreensPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/auth/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/auth/register/register.module').then( m => m.RegisterPageModule),
  },
  {
    path: 'details-ad',
    loadChildren: () => import('./pages/details-ad/details-ad.module').then( m => m.DetailsAdPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'user-ads',
    loadChildren: () => import('./pages/user-ads/user-ads.module').then( m => m.UserAdsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'add-new-ad',
    loadChildren: () => import('./pages/add-new-ad/add-new-ad.module').then( m => m.AddNewAdPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'view-locations',
    loadChildren: () => import('./pages/locations/view-locations/view-locations.module').then( m => m.ViewLocationsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'add-location',
    loadChildren: () => import('./pages/locations/add-location/add-location.module').then( m => m.AddLocationPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'user-profile',
    loadChildren: () => import('./pages/user-profile/user-profile.module').then( m => m.UserProfilePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'terms-of-use',
    loadChildren: () => import('./pages/terms-of-use/terms-of-use.module').then( m => m.TermsOfUsePageModule),
  },
  {
    path: 'is-premium',
    loadChildren: () => import('./pages/is-premium/is-premium.module').then( m => m.IsPremiumPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
