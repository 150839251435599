export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyAINfxHgu1tbwav0yIUT5htbSTmkyUybBs",
    authDomain: "demethra-app-beb80.firebaseapp.com",
    projectId: "demethra-app-beb80",
    databaseURL: "https://demethra-app-beb80-default-rtdb.firebaseio.com",
    storageBucket: "demethra-app-beb80.appspot.com",
    messagingSenderId: "722066298251",
    appId: "1:722066298251:web:450545f70850b8b4890b8e"
  },
  hasura_url: 'https://hs.demethraapp.com.br/v1/graphql',
  hasura_ws_url: 'wss://hs.demethraapp.com.br/v1/graphql',
  upload_url: 'https://upload.demethraapp.com.br',
  terms_of_use_url: 'https://demethra-terms-of-use.s3.amazonaws.com/termo1.txt',
  cdn_url: 'https://demethra-files.s3.us-east-1.amazonaws.com',
};

