import { gql } from 'apollo-angular';

export const PREMIUM_CATEGORY_ID = 3;
export const getApprovedNormalAdvertisementsCards = gql`
  subscription getApprovedNormalAdvertisementsCards {
    advertisements(order_by: {user: {account_category: desc}, updated_at: desc}, where: {approved: {_eq: true}, _and: {source: {_eq: "market"}}}) {
      title
      price
      condition
      created_at
      location {
        city
        uuid
      }
      image: images(path: "[0]")
      uuid
      category {
        uuid
        name
        category_image
      }
    }
  }
`;

export const getApprovedPremiumAdvertisementsCards = gql`
  subscription getApprovedPremiumAdvertisementsCards {
    advertisements(order_by: {updated_at: desc},
      where: {user: {account_category: {_eq: ${PREMIUM_CATEGORY_ID}}}, approved: {_eq: true}, source: {_eq: "premium_market"}}
    ) {
      title
      price
      condition
      location {
        city
        uuid
      }
      image: images(path: "[0]")
      uuid
      category {
        uuid
        name
        category_image
      }
    }
  }

`;

export const getUserAdvertisementsCards = gql`
  subscription getUserAdvertisementsCards($user_uuid: uuid) {
    advertisements(order_by: {updated_at: desc}, where: {user_uuid: {_eq: $user_uuid}}) {
      title
      price
      condition
      approved
      location {
        city
        uuid
      }
      uuid
      category {
        name
        category_image
      }
    }
  }
`;

export const getAdvertisementDetails = gql`
  query getAdvertisementDetails($advertisement_uuid: uuid!) {
    advertisements(where: { uuid: { _eq: $advertisement_uuid } }) {
      title
      technical_info
      price
      location {
        city
        name
        state
        uuid
      }
      category {
        name
      }
      condition
      updated_at
      user {
        name
        whatsapp_phone
      }
      description
      images
      uuid
      user_saved_ads {
        is_active
      }
    }
  }
`;

export const getUserSavedAds = gql`
  query getUserSavedAds {
    user_saved_ads {
      advertisement {
        title
        price
        uuid
        category {
          category_image
          name
        }
        image: images(path: "[0]")
        user {
          uuid
        }
      }
    }
  }
`;

export const upsertUserSavedAd = gql`
  mutation userSavedAd(
    $advertisement_uuid: uuid!
    $user_uuid: uuid!
    $is_active: Boolean!
  ) {
    insert_user_saved_ads_one(
      object: {
        advertisement_uuid: $advertisement_uuid
        user_uuid: $user_uuid
        is_active: $is_active
      }
      on_conflict: {
        constraint: user_saved_ads_pkey
        update_columns: is_active
      }
    ) {
      is_active
      advertisement_uuid
      uuid
    }
  }
`;

export const getAllCategories = gql`
  query getAllCategories {
    categories {
      name
      uuid
      technical_info_fields
    }
  }
`;

export const getPremiumCategories = gql`
  query getPremiumCategories {
    categories(where: {is_premium: {_eq: true}}) {
      name
      uuid
      technical_info_fields
    }
  }
`;

export const getNormalCategories = gql`
  query getNormalCategories {
    categories(where: {is_premium: {_eq: false}}) {
      name
      uuid
      technical_info_fields
    }
  }
`;

export const addNewAd = gql`
  mutation addNewAdvertisement($price: float8!, $category_uuid: uuid!, $condition: String, $source: String!, $description: String, $images: jsonb, $location_uuid: uuid!, $technical_info: jsonb, $title: String, $user_uuid: uuid!) {
    insert_advertisements_one(object: {category_uuid: $category_uuid, condition: $condition, source: $source, description: $description, images: $images, location_uuid: $location_uuid, price: $price, technical_info: $technical_info, title: $title, user_uuid: $user_uuid}) {
      category_uuid
      condition
      description
      images
      location_uuid
      price
      technical_info
      title
      user_uuid
      source
    }
  }
`;

export const inactivateAdvertisement = gql`
  mutation inactivateAdvertisement($uuid: uuid!) {
    update_advertisements_by_pk (
      pk_columns: {uuid: $uuid}
      _set: {is_active: false}
    ) {
      uuid,
      is_active
    }
  }
`;

export const updateAdPrice = gql`
  mutation updateAdPrice($uuid: uuid!, $price: float8!) {
    update_advertisements_by_pk (
      pk_columns: {uuid: $uuid}
      _set: {price: $price}
    ) {
      uuid,
      price
    }
  }
`;

