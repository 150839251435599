import { first } from 'rxjs/operators';
import { inactivateLocation, upsertUserLocation } from './../../../graphql/locations';
import { Apollo } from 'apollo-angular';
import { AddLocationPage } from './../../../../pages/locations/add-location/add-location.page';
import {
  PopoverController,
  ModalController,
  AlertController,
} from '@ionic/angular';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-popover',
  template: `<ion-list>
    <ion-item *ngFor="let i of items" (click)="i.handler()">
      <ion-label>{{ i.title }}</ion-label>
      <ion-icon [name]="i.icon" slot="end" size="small"></ion-icon>
    </ion-item>
  </ion-list>`,
  styleUrls: ['./location-card.component.scss'],
})
export class AppPopoverComponent {
  @Input() items: any[];
}

@Component({
  selector: 'app-location-card',
  templateUrl: './location-card.component.html',
  styleUrls: ['./location-card.component.scss'],
})
export class LocationCardComponent implements OnInit {
  @Input() location: any;
  @Input() isActiveLocation: boolean;
  @Output() inactivate = new EventEmitter<boolean>();
  @Output() selectActiveLocationEmitter = new EventEmitter<string>();


  constructor(
    private popoverCtrl: PopoverController,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private apollo: Apollo
  ) {}

  ngOnInit() {}
  async popover(ev: Event) {
    ev.stopImmediatePropagation();
    const options = await this.popoverCtrl.create({
      component: AppPopoverComponent,
      componentProps: {
        items: [
          {
            title: 'Editar',
            icon: 'pencil-outline',
            handler: async () => {
              // console.log('editar', this.location);
              const modal = await this.modalCtrl.create({
                component: AddLocationPage,
                componentProps: { location:  this.location, edit: true },
              });
              await modal.present();
              this.popoverCtrl.dismiss();
              const result = await modal.onDidDismiss();
              this.location = result.data ? result.data: this.location;
              // console.log(`resultado`, result, this.location);
            },
          },
          {
            title: 'Excluir',
            icon: 'trash-bin-outline',
            handler: async () => {
              await (
                await this.alertCtrl.create({
                  header: 'Deseja realmente excluir essa localização',
                  buttons: [
                    {text: 'Cancelar', role: 'cancel'},
                    {
                      text: 'Confirmar',
                      handler: async () => {
                        // console.log('Excluir', location);
                        const res = await this.apollo.mutate({
                          mutation: inactivateLocation,
                          variables: {
                            uuid: this.location.uuid
                          },
                        }).pipe(first()).toPromise();
                        if (res.data) {
                          this.inactivate.emit(true);
                        }
                        this.popoverCtrl.dismiss();
                      },
                    },
                  ],
                })
              ).present();
            },
          },
        ],
      },
      event: ev,
      translucent: true,
    });
    options.present();
    const { role } = await options.onDidDismiss();
    // console.log(role);
  }

  selectActiveLocation() {
    localStorage.setItem('activeLocation', JSON.stringify({uuid: this.location.uuid, name: `${this.location.city} / ${this.location.state}`}));
    this.selectActiveLocationEmitter.emit(this.location.uuid);
  }
}
