import { gql } from 'apollo-angular';

export const getUserProfile = gql`
  query getUserProfile($firebase_uid: String!) {
    user(where: {firebase_uid: {_eq: $firebase_uid}}) {
      email
      is_company
      lastname
      name
      uuid
      whatsapp_phone
      profile_photo
      document
      created_at
      account_category
    }
  }
`;

export const getUserProfileAllFields = gql`
  query getUserProfileAllFields($firebase_uid: String!) {
    user(where: {firebase_uid: {_eq: $firebase_uid}}) {
      uuid
      name
      lastname
      profile_photo
      whatsapp_phone
      is_company
      email
      document
    }
  }
`;

export const upsertUserProfile = gql`
  mutation upsertUserProfile(
    $lastname: String
    $document: String
    $email: String
    $name: String
    $uuid: uuid
    $profile_photo: String
    $is_company: Boolean = false
  ) {
    insert_user_one(
      object: {
        lastname: $lastname
        document: $document
        email: $email
        name: $name
        uuid: $uuid
        profile_photo: $profile_photo
        is_company: $is_company
      }
      on_conflict: {
        constraint: user_pkey
        update_columns: [
          email
          name
          lastname
          document
          profile_photo
          is_company
        ]
      }
    ) {
      document
      email
      lastname
      name
      profile_photo
      is_company
    }
  }
`;

export const deleteUser = gql`
  mutation deleteUser($uuid: uuid!) {
    delete_user_by_pk(uuid: $uuid) {
      uuid
    }
  }
`;

export const insertUser = gql`
  mutation insertUser(
    $is_company: Boolean!
    $lastname: String
    $name: String!
    $email: String!
    $whatsapp_phone: String!
  ) {
    insert_user_one(
      object: {
        is_company: $is_company
        lastname: $lastname
        name: $name
        email: $email
        whatsapp_phone: $whatsapp_phone
      }
    ) {
      is_company
      lastname
      name
      email
      whatsapp_phone
    }
  }
`;

export const updateUser = gql`
  mutation updateUser($uuid: uuid!, $changes: user_set_input) {
    update_user_by_pk(pk_columns: {uuid: $uuid}, _set: $changes) {
      email
      lastname
      name
      document
    }
  }
`;
