import { first } from 'rxjs/operators';
import { UserService } from 'src/app/shared/services/user.service';
import { Apollo } from 'apollo-angular';
import { upsertUserLocation } from './../../../shared/graphql/locations';
import { ModalController, ToastController } from '@ionic/angular';
import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UUID } from 'angular2-uuid';
import { AutocompleteCityListComponent } from 'src/app/shared/components/autocomplete-city-list/autocomplete-city-list.component';
import { HttpClient } from '@angular/common/http';

const states = [
  { acronym: 'AC', name: 'Acre' },
  { acronym: 'AL', name: 'Alagoas' },
  { acronym: 'AP', name: 'Amapá' },
  { acronym: 'AM', name: 'Amazonas' },
  { acronym: 'BA', name: 'Bahia' },
  { acronym: 'CE', name: 'Ceará' },
  { acronym: 'DF', name: 'Distrito Federal' },
  { acronym: 'ES', name: 'Espírito Santo' },
  { acronym: 'GO', name: 'Goías' },
  { acronym: 'MA', name: 'Maranhão' },
  { acronym: 'MT', name: 'Mato Grosso' },
  { acronym: 'MS', name: 'Mato Grosso do Sul' },
  { acronym: 'MG', name: 'Minas Gerais' },
  { acronym: 'PA', name: 'Pará' },
  { acronym: 'PB', name: 'Paraíba' },
  { acronym: 'PR', name: 'Paraná' },
  { acronym: 'PE', name: 'Pernambuco' },
  { acronym: 'PI', name: 'Piauí' },
  { acronym: 'RJ', name: 'Rio de Janeiro' },
  { acronym: 'RN', name: 'Rio Grande do Norte' },
  { acronym: 'RS', name: 'Rio Grande do Sul' },
  { acronym: 'RO', name: 'Rondônia' },
  { acronym: 'RR', name: 'Roraíma' },
  { acronym: 'SC', name: 'Santa Catarina' },
  { acronym: 'SP', name: 'São Paulo' },
  { acronym: 'SE', name: 'Sergipe' },
  { acronym: 'TO', name: 'Tocantins' },
];
@Component({
  selector: 'app-add-location',
  templateUrl: './add-location.page.html',
  styleUrls: [
    './add-location.page.scss',
    '../view-locations/view-locations.page.scss',
  ],
})
export class AddLocationPage implements OnInit {
  @Input() location: any;
  @Input() edit = false;
  @ViewChild('stateSelect') stateSelect: any;
  title = 'Localizações';
  locationForm: FormGroup = new FormGroup({
    city: new FormControl(),
    state: new FormControl(),
    // complement: new FormControl(),
    // name: new FormControl(),
    // neighborhood: new FormControl(),
    // number: new FormControl(),
    // street: new FormControl(),
    // zipcode: new FormControl(),
    user_uuid: new FormControl(),
    uuid: new FormControl(),
  });
  states = states;

  constructor(
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private toastCtrl: ToastController,
    private apollo: Apollo,
    private userService: UserService,
    private http: HttpClient
  ) {

  }
  async ngOnInit() {
    // console.log(this.location, this.edit);
    await this.initForm();
  }

  async initForm() {
    this.locationForm = this.formBuilder.group({
      city: [
        this.location?.city,
        [Validators.required, Validators.minLength(2)],
      ],
      state: [
        this.location?.state,
        [Validators.required, Validators.minLength(2), Validators.maxLength(2)],
      ],
      user_uuid: [(await this.userService.getUserFromLocalStorage()).uuid],
      uuid: [this.location?.uuid || UUID.UUID()],
      // complement: [this.location?.complement],
      // name: [
      //   this.location?.name,
      //   [Validators.required, Validators.minLength(2)],
      // ],
      // neighborhood: [
      //   this.location?.neighborhood,
      //   [Validators.required, Validators.minLength(2)],
      // ],
      // number: [
      //   this.location?.number,
      //   [Validators.required, Validators.minLength(1)],
      // ],
      // street: [
      //   this.location?.street,
      //   [Validators.required, Validators.minLength(2)],
      // ],
      // zipcode: [
      //   this.location?.zipcode,
      //   [Validators.required, Validators.minLength(8), Validators.maxLength(9)],
      // ],
    });
  }

  setStateControl(event) {
    // console.log(event);
    const acronynm = event.detail.value;
    this.locationForm.get('state')?.setValue(acronynm);
  }

  async save() {
    // console.log(this.locationForm.value);
    let [message, color] = ['Localização salva com sucesso!', 'success'];
    if (this.locationForm.valid) {
      // const zipcode = this.locationForm.get('zipcode');
      // zipcode?.setValue(zipcode?.value.replace(/\D/g, ''));
      const location: any = await this.apollo
        .mutate({
          mutation: upsertUserLocation,
          variables: this.locationForm.value,
        })
        .pipe(first())
        .toPromise();
      [message, color] = location.data ? [message, color] : ['Houve um erro, tente novamente mais tarde.', 'danger'];
      this.modalCtrl.dismiss(location.data.insert_locations_one);
    } else {
      [message, color] = ['Dados inválidos, revise e tente novamente!', 'warning'];
    }
    this.toastCtrl.create({
      message,
      color,
      duration: 4000,
    }).then(t => t.present());
  }

  findAddress(){
    const zipcode = `${this.locationForm.get('zipcode')?.value}`;
    
    if(zipcode.length == 8) {
      const address = this.http.get(`https://viacep.com.br/ws/${zipcode}/json`).subscribe((res: any) => {
        this.locationForm.get('street')?.setValue(res.logradouro);
        this.locationForm.get('neighborhood')?.setValue(res.bairro);
        this.locationForm.get('city')?.setValue(res.localidade);
        this.locationForm.get('state')?.setValue(res.uf);
        this.stateSelect.value = res.uf
        
      });
      
    }
  }

  async openCityAutoComplete(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    const citiesModal = await this.modalCtrl.create({
      component: AutocompleteCityListComponent
    });
    await citiesModal.present();
    const modalDimissed = await citiesModal.onDidDismiss();
    if(!!modalDimissed.data) {
      this.locationForm.get('city')?.setValue(modalDimissed.data.city);
      this.locationForm.get('state')?.setValue(modalDimissed.data.state);
      this.stateSelect.value = modalDimissed.data.state;
    }
  }

  backToListLocal() {
    this.modalCtrl.dismiss();
  }
}
