import { Component, OnInit } from '@angular/core';
import { AuthService } from './shared/services/auth.service';
import initFirebaseApp from './firebaseInit';
import { Router } from '@angular/router';
import firebase from 'firebase';
import { LoadingController, ToastController } from '@ionic/angular';
import { UserService } from './shared/services/user.service';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  loaded = false;
  constructor(
    private auth: AuthService,
    private router: Router,
    private loadingController: LoadingController,
    private toast: ToastController,
    private userService: UserService,
    private screenOrientation: ScreenOrientation
  ) {
    initFirebaseApp();
  }

  clearLocalStorage() {
    const location = localStorage.activeLocation;
    localStorage.clear();
    if (location) {
      localStorage.activeLocation = location;
    }
  }

  async ngOnInit() {
    this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
    this.clearLocalStorage();
    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    firebase.auth().languageCode = 'pt';
    firebase.auth().onAuthStateChanged(async (user) => {
      // console.log('@onAuthStateChanged', firebase.auth().currentUser);
      if (localStorage.registerProcess) return;
      const load = await this.loadingController.create({message: 'Carregando...'});
      if(user) {
        // load.present();
        setTimeout(() => {
          load.dismiss();
        }, 8000);
        await this.auth.setHasuraJwt();
        await this.auth.delay(2000);
        const userData: any = await this.userService.queryUserProfile();
        if(userData?.data && (!userData.data?.user || !userData.data?.user?.length)){
          (await this.toast.create({
            message: 'Erro no servidor... Tente novamente em alguns minutos',
            color: 'danger',
            duration: 4000
          })).present();
        } else {
          this.router.navigateByUrl('main-screens', { replaceUrl: true });
        }
      } else if(!this.loaded){
        this.router.navigateByUrl('login', { replaceUrl: true });
      }
      load.dismiss();
      this.loaded = true;
    }, (err) => {
      // console.log('onAuthStateChanged error ->', err)
    });
  }
}
